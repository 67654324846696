import React from 'react';

import { PendingNotificationItem } from './notification-items';

const TabPending = props => {
  let {active, notifications, onAccept, onDecline, onAcceptAll, activeNotificationId} = props
  let active_class = active ? ' in active' : '';
  return (
    <div className={"tab-pane fade " + active_class}>
      <div className="row patient-notif-accept-all">
        {notifications.length >= 1 && (
          <a
            href="#"
            className="btn btn-light-outline"
            onClick={() => onAcceptAll()}
          >
            Accept All <i className="fa fa-check-square"></i>
          </a>
        )}

        <span className='i-tooltip'>
          <i className="fa fa-info-circle"></i>
          <span className="i-tooltiptext">
            If you accept, your record will be updated. If you decline, The patient's record will still 
            reflect the change but yours will not. The patient will not be notified of your choice.
          </span>
        </span>
      </div>

      {notifications.map((n, index) => (
        <PendingNotificationItem
          key={index}
          activeNotificationId={activeNotificationId}
          notification={n}
          onAccept={onAccept}
          onDecline={onDecline}
        />
      ))}

      { notifications.length == 0 &&
        <div className="panel panel-default patient-notif">
          <div className="panel-body--no-updates">
            <div className="row">
              <div className="col-md-6">
                <p className="">
                  There are no pending updates at this time <br />{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      }

      <br />
      <br />
    </div>
  );
};

export default TabPending;