import React, { Component } from 'react';

import account_api from '../../api/user-account-api'
import authentication_api from '../../api/authentication'
import ModalStaff from './modal-staff';
import ModalDeleteStaff from './modal-delete-staff';
import TableFilter from '../tables/table-filter';
import TableFooter from '../tables/table-footer';
import { forIn, cloneDeep } from 'lodash';
import { createUUID } from '../react-flow-pedigree/utils';

const types = ["trial_org"];

class StaffTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStaffModal: false,
      showDeleteStaffModal: false,
      currentStaffMember: null,
      currentStaffMemberKey: null,
      addingNewStaff: false,
      entries_per_page: 10,
      page: 1,
      staff: {},
      searchText: '',
      loginTypeOptions: []
    };

    this.refreshStaff      = this.refreshStaff.bind(this);
    this.openStaffModal    = this.openStaffModal.bind(this);
    this.closeStaffModal   = this.closeStaffModal.bind(this);
    this.openDeleteStaffModal  = this.openDeleteStaffModal.bind(this);
    this.closeDeleteStaffModal = this.closeDeleteStaffModal.bind(this);

    this.onChange = this.onChange.bind(this);
    this.onSaveStaffMember = this.onSaveStaffMember.bind(this);
    this.onDeleteStaffMember = this.onDeleteStaffMember.bind(this);

    this.filterValues = this.filterValues.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
  }

  async componentDidMount() {
    const loginTypeOptions = await account_api.get_org_sso();
    this.setState({loginTypeOptions: loginTypeOptions});
    this.refreshStaff();
    if (this.props.setCallbackTrigger) {
      this.props.setCallbackTrigger(this.refreshStaff);
    }
  }

  async refreshStaff() {
    try {
      let data = await account_api.get_org_staff_accounts(this.props.org_id);
      let staff = {};
      data.staff.forEach((staff_member) => {
        staff[createUUID()] = staff_member;
      });
      this.setState({staff});
    } catch (error) {
      console.log(error);
    }
  }

  async openStaffModal(event) {
    let staff_member_key = event.currentTarget.name;
    let staff_member = this.state.staff[staff_member_key];
    let addingNewStaff = false;
    let trial_expiration = null;

    if(staff_member === null || staff_member === undefined) {
      staff_member_key = createUUID();
      staff_member = {
        staff_id: null,
        user_id: null,
        account_id: null,
        username: '',
        first_name: '',
        last_name: '',
        login_type: '',
        email: '',
        admin: false,
        is_lock: false,
        trial_expiration: null
      };
      addingNewStaff = true;
    }

    if (this.props.org.org_type) {
      if (types.includes(this.props.org.org_type)) {
        trial_expiration = await account_api.get_trial_staff(staff_member.staff_id);
        if (trial_expiration) {
          staff_member.trial_expiration = trial_expiration;
        }
      }
    }

    this.setState({
      showStaffModal: true,
      currentStaffMember: staff_member,
      currentStaffMemberKey: staff_member_key,
      addingNewStaff: addingNewStaff
    });
  }

  closeStaffModal(event) {
    this.setState({showStaffModal: false, currentStaffMember: null, currentStaffMemberKey: null, addingNewStaff: false});
  }

  openDeleteStaffModal(event) {
    let staff_member_key = event.currentTarget.name;
    let staff_member = this.state.staff[staff_member_key];

    if(staff_member === null || staff_member === undefined) {
      return;
    }

    this.setState({
      showDeleteStaffModal: true,
      currentStaffMember: staff_member,
      currentStaffMemberKey: staff_member_key
    });
  }

  closeDeleteStaffModal(event) {
    this.setState({showDeleteStaffModal: false, currentStaffMember: null, currentStaffMemberKey: null});
  }

  async onChange(event) {
    // doing a deep clone here because there are a few nested objects/arrays in the state
    // {staffKey: {...}, ...}
    let cloned = cloneDeep(this.state.staff);

    const name = event.target.name;
    const locked = event.target.checked;

    let staff_member = cloned[name];
    if(staff_member === null || staff_member === undefined) {
      return;
    }

    try {
      await account_api.patch_staff_account(staff_member.staff_id, { is_lock: locked });

      staff_member.is_lock = locked;
      cloned[name] = staff_member;
      this.setState({ staff: cloned });
    } catch (error) {
      // TODO: show error message
      console.log(error);
    }
  }

  async onSaveStaffMember(payload) {
    // doing a deep clone here because there are a few nested objects/arrays in the state
    // {staffKey: {...}, ...}
    let cloned = cloneDeep(this.state.staff);

    let { staffKey, staffMember } = payload;

    let staff_member = Object.assign({}, staffMember);

    const json = {
      username: staff_member.username,
      first_name: staff_member.first_name,
      last_name: staff_member.last_name,
      login_type: staff_member.login_type,
      email: staff_member.email,
      is_lock: staff_member.is_lock,
      login_type: staff_member.login_type,
      access_type: (staff_member.admin) ? 'admin' : 'normal',
      organization: this.props.org_id
    };

    try {
      let staff_id = null;
      if(staff_member.staff_id) {
        let data = await account_api.patch_staff_account(staff_member.staff_id, json);
        staff_id = data.staff_id;

      } else {
        const new_staff_response = await account_api.post_new_staff(json);
        staff_id = new_staff_response.staff.id;

        let response = await authentication_api.send_password_reset_email(staff_member.username, true);
      }

      cloned[staffKey] = staff_member;
      this.setState({ staff: cloned });

      // add expiration date to staff if org is trial
      // use username to find account -> find staff based on account id
      await account_api.update_trial_staff(staff_id, staff_member.trial_expiration);

    } catch (error) {
      console.log(error);
    }

    this.refreshStaff();
  }

  async onDeleteStaffMember(payload) {
    // doing a deep clone here because there are a few nested objects/arrays in the state
    // {staffKey: {...}, ...}
    let cloned = cloneDeep(this.state.staff);

    // payload is {deleteKey}
    let { deleteKey } = payload;
    let staff_member = cloned[deleteKey];

    try {

      let data = await account_api.delete_staff_account(staff_member.staff_id);
      delete cloned[deleteKey];
      this.setState({ staff: cloned });

    } catch (error) {
      // TODO: show error message
      console.log(error);
    }
  }

  filterValues(value) {
    this.setState({entries_per_page: value, page: 1});
  }

  async onSearchSubmit(search) {
    // TODO: implement client side search, even if the org had 2000 staff it would still be a quick search
    if(search === null || search === undefined || search === '') {
      this.refreshStaff();
    } else {
      try {
        const staff_members = await account_api.search_staff(this.props.org_id, search);
        if(staff_members !== null) {
          let staff = {};
          staff_members.forEach((staff_member) => {
            staff[createUUID()] = staff_member;
          });
          this.setState({ staff: staff });
        }
      } catch (error) {
        // TODO: show error message
        console.log(error);
      }
    }
  }

  downloadCSV() {
    let csv = '';
    csv += 'Username,First Name,Last Name,Email,Access,Account Locked\r\n';
    forIn(this.state.staff, (staff_member, staffKey) => {
      const username = staff_member.username;
      const first_name = staff_member.first_name;
      const last_name = staff_member.last_name;
      const email = staff_member.email;
      const access_type = (staff_member.admin) ? 'Admin' : 'Normal';
      const account_locked = (staff_member.is_lock) ? 'Yes' : 'No';
      const line = `${username},${first_name},${last_name},${email},${access_type},${account_locked}\r\n`;
      csv += line;
    });

    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  handleChangeSearchText(search_text) {
    this.setState({searchText: search_text, page: 1});
  }

  render() {
    let counter = 1;
    const staff_members = this.state.staff;
    const onChange = this.onChange;
    const openStaffModal = this.openStaffModal;
    const openDeleteStaffModal = this.openDeleteStaffModal;
    const start_index = this.state.page === 1 ? 0 : (this.state.entries_per_page * (this.state.page - 1));
    const end_index = this.state.entries_per_page * this.state.page;

    var staff_filtered_entries = Object.entries(staff_members).filter(member => {
      let {username, first_name} = member[1];
      let hidden_user = member[1].hidden_user
      let target_text = username.toLowerCase() + first_name.toLowerCase();
      let search_text = this.state.searchText.toLowerCase();
      return target_text.includes(search_text) && hidden_user === false;
    });

    const page_entries = staff_filtered_entries.slice(start_index, end_index);
    const tableRows = page_entries.map((staff_member) => {
      return (
        <tr key={staff_member[0]} className={( (counter++ % 2) === 0) ? "even" : "odd"}>
          <td className="middle">{staff_member[1].username}</td>
          <td className="middle">{staff_member[1].first_name}</td>
          <td className="middle">{staff_member[1].last_name}</td>
          <td className="middle">{(staff_member[1].admin) ? 'Admin' : 'Normal'}</td>
          {this.state.loginTypeOptions.length > 1 &&
            (<td className="middle">{staff_member[1].login_type}</td>)
          }
          <td className="middle">
            <div className="checkbox">
              <label className="switch">
                <input name={staff_member[0]} id={staff_member[0]} type="checkbox" checked={staff_member[1].is_lock} onChange={onChange} />
                <span className="slider round"></span>
              </label>
            </div>
          </td>
          <td className="middle">
            <button className="btn btn-teal table-btn-edit margin-right-six" onClick={openStaffModal} name={staff_member[0]}><i className="fa fa-pencil"></i>Edit</button>
            <button className="btn table-btn-edit bg-transparent" onClick={openDeleteStaffModal} name={staff_member[0]}><i className="fa fa-trash"></i></button>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        {/* <div className="form-header">
          <div className="row vcenter">
            <div className="col-md-6 col-xs-12">
                <h3 className="title">Staff</h3>
            </div>
            <div className="col-md-4 col-md-offset-2 col-xs-12 justify-right">
              <button className={this.props.headerBtnCssClass} onClick={this.openStaffModal}><i className="fa fa-plus"></i> Add New Staff</button>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            {this.props.showFilter && (
              <TableFilter
                tableType="staff"
                onChangeSearchText={ value => this.handleChangeSearchText(value)}
                filterValues={(value) => this.filterValues(value)}
                onSearchSubmit={(search) => this.onSearchSubmit(search)}
                downloadCSV={this.downloadCSV}
              />
            )}

            <table className="table table-custom responsive no-wrap margin-one">
              <thead>
                {this.state.loginTypeOptions.length === 1 ?
                  <tr>
                    <th style={{width: '130px'}}>Username</th>
                    <th style={{width: '134px'}}>First Name</th>
                    <th style={{width: '130px'}}>Last Name</th>
                    <th style={{width: '147px'}}>Access Type</th>
                    <th style={{width: '152px'}}>Lock</th>
                    <th style={{width: '231px'}}> </th>
                  </tr>
                  :
                  <tr>
                    <th style={{width: '130px'}}>Username</th>
                    <th style={{width: '134px'}}>First Name</th>
                    <th style={{width: '130px'}}>Last Name</th>
                    <th style={{width: '147px'}}>Access Type</th>
                    <th style={{width: '147px'}}>Login Type</th>
                    <th style={{width: '152px'}}>Lock</th>
                    <th style={{width: '231px'}}> </th>
                  </tr>
                }

              </thead>

              <tbody>
                {tableRows}
              </tbody>
            </table>

            {staff_filtered_entries.length > 0 && (
              <TableFooter
                entries={staff_filtered_entries.length}
                entries_per_page={this.state.entries_per_page}
                page={this.state.page}
                page_change={(page) => this.setState({page})}
              />
            )}
          </div>
        </div>

        {this.state.showStaffModal && (
          <ModalStaff
            loginTypeOptions = {this.state.loginTypeOptions}
            staffMember={this.state.currentStaffMember}
            staffKey={this.state.currentStaffMemberKey}
            addingNewStaff={this.state.addingNewStaff}
            onClose={this.closeStaffModal}
            onClickSave={(payload) => this.onSaveStaffMember(payload)}
            org={this.props.org}
          />
        )}

        {this.state.showDeleteStaffModal && (
          <ModalDeleteStaff
            staffMember={this.state.currentStaffMember}
            staffKey={this.state.currentStaffMemberKey}
            onClose={this.closeDeleteStaffModal}
            onClickDelete={(payload) => this.onDeleteStaffMember(payload)}
          />
        )}

      </React.Fragment>
    );
  }
}

export default StaffTable;
