import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { PedigreeStylesheet } from './pedigree-constants';

export const FATHER_ANCESTRY_ID = "father_ancestry_node";
export const MOTHER_ANCESTRY_ID = "mother_ancestry_node";

export default memo(({ id, data, xPos, yPos, type, selected, connectable, sourcePosition, targetPosition }) => {

  const ancestry_type = (id === FATHER_ANCESTRY_ID) ? "Paternal Ancestry" : "Maternal Ancestry";
  let node_build = (
    <>
      <div style={PedigreeStylesheet.ancestryNodeLabel}>
        {ancestry_type}
      </div>
      <div style={PedigreeStylesheet.ancestryNode}>
        {data.label}
      </div>
    </>
  );

  return (
    <>
      <Handle type="target" position={targetPosition} connectable={connectable} hidden={true} />
      {node_build}
      <Handle type="source" position={sourcePosition} connectable={connectable} hidden={true} />
    </>
  );
});
