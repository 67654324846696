import React, { Component } from 'react';
import images from '../../configs/images';
import settings from '../../configs/settings';
import { connect } from "react-redux";

const types = ["trial_org"];

class SubAccountDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let risk_model_years_response = this.props.org.risk_model_years
    if (risk_model_years_response == 'five-year' ) {
      risk_model_years_response = '5 year'
    } else if (risk_model_years_response == 'ten-year') {
      risk_model_years_response = '10 year'
    } else {
      risk_model_years_response = '5 and 10 Years'
    }


    let boadicea_css = 'fa fa-times';
    let tyrer_cuzick_css = 'fa fa-times';
    let gail_css = 'fa fa-times';
    let claus_css = 'fa fa-times';
    let brcapro_css = 'fa fa-times';
    let mmrpro_css = 'fa fa-times';
    let pancpro_css = 'fa fa-times';
    let melapro_css = 'fa fa-times';
    let showGenderIdentity_css = 'fa fa-times';
    if(this.props.org.license) {
      if(this.props.org.license.boadicea) boadicea_css = 'fa fa-check';
      if(this.props.org.license.tyrer_cuzick) tyrer_cuzick_css = 'fa fa-check';
      if(this.props.org.license.gail) gail_css = 'fa fa-check';
      if(this.props.org.license.claus) claus_css = 'fa fa-check';
      if(this.props.org.license.brcapro) brcapro_css = 'fa fa-check';
      if(this.props.org.license.mmrpro) mmrpro_css = 'fa fa-check';
      if(this.props.org.license.pancpro) pancpro_css = 'fa fa-check';
      if(this.props.org.license.melapro) melapro_css = 'fa fa-check';
    }

    if(this.props.org.show_gender_identity) {
      showGenderIdentity_css = 'fa fa-check';
    }

    let org_type = null;
    switch(this.props.org.org_type) {
      case 'super_org': { org_type = settings.app_constants.superOrg.label; break; }
      case 'sub_org': { org_type = settings.app_constants.subOrg.label; break; }
      case 'standard_org': { org_type = settings.app_constants.standardOrg.label; break; }
      case 'trial_org': { org_type = settings.app_constants.trialOrg.label; break; }
      case 'free': { org_type = settings.app_constants.freeOrg.label; break; }
      case 'student': { org_type = settings.app_constants.studentOrg.label; break; }
      default: { break; }
    }

    let race_ethnicity_display = null
    switch(this.props.org.race_ethnicity_display){
      case 'show_ashkanazi': { race_ethnicity_display = settings.app_constants.ashkanaziOnly.label; break; }
      case 'show_ashkanazi_and_race': { race_ethnicity_display = settings.app_constants.ashkanaziAndRace.label; break; }
      case 'hide_ashkanazi_and_race': { race_ethnicity_display = settings.app_constants.hideAshkanaziAndRace.label; break; }
      default: { break; }
    }

    let auto_logout_time = null;
    auto_logout_time = (this.props.org.auto_logout_time) ? this.props.org.auto_logout_time : null;
    if (auto_logout_time) {
      if (auto_logout_time >= 60) {
        auto_logout_time = auto_logout_time / 60 + ((auto_logout_time==60) ? ' Hour' : ' Hours')
      } else {
        auto_logout_time = auto_logout_time + ' Minutes'
      }
    }

    const password_reset = (this.props.org.password_reset_timeframe) ? this.props.org.password_reset_timeframe + ' Days' : null;
    
    const show_code = (this.props.session?.user.account_type === 'super_admin' ? true: false)

    const code = (this.props.org.clinician_code && (this.props.org.org_type !== 'individual' || show_code) ) ? this.props.org.clinician_code.code: null;

    const parent_org = (this.props.org.parent_organization) ? this.props.org.parent_organization.org.org_name : '';

    const trial_org = (types.includes(this.props.org.org_type)) ? true : false;

    let default_partner_view = '';
    if (this.props.org.default_partner_view){
      switch(this.props.org.default_partner_view){
        case 'show_all_partners': { default_partner_view = settings.app_constants.defaultPartnerViewOptions.show_all_partners.label; break; }
        case 'show_affected_partners': { default_partner_view = settings.app_constants.defaultPartnerViewOptions.show_affected_partners.label; break; }
        case 'hide_partners': { default_partner_view = settings.app_constants.defaultPartnerViewOptions.hide_partners.label; break; }
        default: { break; }
      }
    }

    return (
      <div className="form-horizontal">
        <div className="row">
          <div className="col-md-8">
            <div className="padding-left-right">

              <div className="form-group">
                <label className="control-label col-md-5 col-sm-3 col-xs-12">Organization</label>
                <div className="col-md-3 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{this.props.org.org_name}</strong></label>
                </div>
              </div>

            <div className="form-group">
                <label className="control-label col-md-5 col-sm-3 col-xs-12">Clinician Code</label>
                <div className="col-md-3 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{code}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-5 col-sm-3 col-xs-12">Account Type</label>
                <div className="col-md-3 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{org_type}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-5 col-sm-3 col-xs-12">Surveys</label>
                <div className="col-md-3 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{this.props.survey_display}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-5 col-sm-3 col-xs-12">Number of Licenses</label>
                <div className="col-md-3 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{this.props.org.number_of_licenses}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-5 col-sm-3 col-xs-12">Max Number of Pedigrees</label>
                <div className="col-md-3 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{this.props.org.max_number_of_pedigrees}</strong></label>
                </div>
              </div>

              {trial_org && (
                <div className="form-group">
                  <label className="control-label col-md-5 col-sm-3 col-xs-12">Trial Expiration</label>
                  <div className="col-md-3 col-sm-9 col-xs-12">
                    <label className="control-label"><strong>{this.props.org.trial_expiration}</strong></label>
                  </div>
              </div>
              )}

              <div className="form-group">
                <label className="control-label col-md-5 col-sm-3 col-xs-12">License Details</label>
                <div className="col-md-3 col-sm-9 col-xs-12">
                  <React.Fragment>
                    <i className={boadicea_css}></i><label className="control-label"><strong>BOADICEA</strong></label>
                    <br />
                  </React.Fragment>

                  <React.Fragment>
                    <i className={tyrer_cuzick_css}></i><label className="control-label"><strong>TYRER-CUZICK</strong></label>
                    <br />
                  </React.Fragment>

                  <React.Fragment>
                    <i className={gail_css}></i><label className="control-label"><strong>GAIL</strong></label>
                    <br />
                  </React.Fragment>

                  <React.Fragment>
                    <i className={claus_css}></i><label className="control-label"><strong>CLAUS</strong></label>
                    <br />
                  </React.Fragment>

                  <React.Fragment>
                    <i className={brcapro_css}></i><label className="control-label"><strong>BRCAPRO</strong></label>
                    <br />
                  </React.Fragment>

                  <React.Fragment>
                    <i className={mmrpro_css}></i><label className="control-label"><strong>MMRPRO</strong></label>
                    <br />
                  </React.Fragment>

                  <React.Fragment>
                    <i className={pancpro_css}></i><label className="control-label"><strong>PANCPRO</strong></label>
                    <br />
                  </React.Fragment>

                  <React.Fragment>
                    <i className={melapro_css}></i><label className="control-label"><strong>MELAPRO</strong></label>
                  </React.Fragment>
                </div>
              </div>

            </div>
          </div>

          <div className="col-md-4">
            <div className="padding-left-right">
              <div className="form-group">
                <label className="control-label col-md-4 col-sm-3 col-xs-12">Customer Logo </label>
                <div className="col-md-8 col-sm-9 col-xs-12">
                  <img src={this.props.org.logo ? this.props.org.logo : images.logoSample} alt="Organization Logo" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="padding-left-right">

              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Risk Model Years</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{risk_model_years_response}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Prompt all for gender identity</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <React.Fragment>
                    <i className={showGenderIdentity_css}></i><label className="control-label"><strong>Show gender identity
                            and pronoun questions on same screen as biological sex</strong></label>
                    <br />
                  </React.Fragment>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Race/Ethnicity Screen</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{race_ethnicity_display}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Default partner view</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{default_partner_view}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Units</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{(this.props.org.units === 'us_standard') ? 'US Standard' : 'Metric'}</strong></label>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Default Country Dial Code</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <label className="control-label" style={{textTransform:'uppercase'}}><strong>{this.props.org.dial_code}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Password Reset Time</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{password_reset}</strong></label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-3 col-sm-3 col-xs-12">Auto-Logout Time</label>
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <label className="control-label"><strong>{auto_logout_time}</strong></label>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

// export default AccountTabDisplay;
const redux_state = (state) => ({
  session: state.session
});

const redux_actions = (dispatch) => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SubAccountDetails);

