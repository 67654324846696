import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { cloneDeep } from 'lodash'
import { RadioGroup, Radio, CheckboxGroup, Checkbox } from 'react-ui-icheck';
import Select from 'react-select';
import ValidationErrorSummary from '../validation-error-summary';
import helpers from '../../helpers/index';
import settings from '../../configs/settings';
import account_api from '../../api/user-account-api'
import { radix_bases } from '../../helpers/helper-number-bases';
import IntlTelInput from 'react-intl-tel-input';
import * as countryCodes from '../patient-profile/patient-data-tabs/dial-code'

import mime_types from '../../configs/mime_types';

const types = ["trial_org"];

function parseToInt(val) {
  return parseInt(val, radix_bases.base10);
}

class ModalEditSuperAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.org,
      fiveYearChecked: ((this?.props?.org?.risk_model_years == "five-year"  )
      || (this?.props?.org?.risk_model_years == "both")) ? true : false,
      tenYearChecked: ((this?.props?.org?.risk_model_years == "ten-year")
      || (this?.props?.org?.risk_model_years == "both")) ? true : false,
      is_parent: false,
      validations: null,
      orgs: [],
      validCode: true,
      uniqueName: true,
      newLogo: null,
      surveys: [],
      survey_options: [],
      trial_expiration: null
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.validate = this.validate.bind(this);

    this.fileInputLabel = React.createRef();
    this.race_ethnicity_check = this.race_ethnicity_check.bind(this);
  }

  async componentDidMount() {
    try {
      this.race_ethnicity_check()
      const orgs = await account_api.get_orgs();
      this.setState({ orgs: orgs });

      this.setState({ surveys: cloneDeep(this.props.selected_surveys) });

      let survey_options = await account_api.get_surveys();
      survey_options = survey_options.map((survey) => {
        return {value: survey.id, label: survey.name}
      });
      // calling by iteself because it wasn't working when combined with other state
      this.setState({survey_options: survey_options});

      for(let org of orgs) {
        if(org.parent_org === this.state.id) {
          this.setState({is_parent: true});
          break;
        }
      }

      if (this.props.org.trial_expiration) {
        this.setState({ trial_expiration: this.props.org.trial_expiration})
      }
    } catch (error) {
      console.log(error);
    }
  }

  race_ethnicity_check(){
    if(!this.state.race_ethnicity_display) this.setState({race_ethnicity_display: settings.app_constants.ashkanaziAndRace.value})
  }

  async onClickSave() {
    // check and make sure the user has chosen the required fields before letting them save a document
    let isValid = this.validate();
    if (!isValid) return;

    let org = cloneDeep(this.state);

    delete org.is_parent;
    delete org.validations;
    delete org.orgs;
    delete org.validCode;
    delete org.uniqueName;

    const getRiskModelYears = () => {
      if (this.state.fiveYearChecked && this.state.tenYearChecked) {
        return 'both'
      } else if (this.state.tenYearChecked) {
        return 'ten-year'
      } else {
        return 'five-year'
      }
      // do not delete newLogo its used in s OnClickSave in Ancestor
    }

    if (types.includes(org.org_type) && org.trial_expiration) {
      await account_api.update_trial_org(org.id, this.state.trial_expiration);
    }

    this.props.onClickSave({...org, risk_model_years: getRiskModelYears()});
    this.props.onClose();

  }


  async onChange(event) {
    const name = event.target.name;
    const data = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;

    if (name === 'boadicea' || name === 'tyrer_cuzick'
      || name === 'claus' || name === 'gail'
      || name === 'brcapro' || name === 'mmrpro'
      || name === 'pancpro' || name == 'melapro') {
      let license = cloneDeep(this.state.license);
      license[name] = data;
      this.setState({license});
    } else if(name === 'code') {
      let clinician_code = cloneDeep(this.state.clinician_code);
      clinician_code[name] = data;
      this.setState({clinician_code});
    } else this.setState({[name]: data});

    await this.changeValidations(name, data);
  }

  async changeValidations(name, data) {
    // Code validation
    if(name === 'code') {
      try {
        await account_api.validate_org_code({clinicianCode: data});
        this.setState({validCode: true});
      } catch(error){
        console.log(error);
        this.setState({validCode: false});
      }
    }

    // Org Validation
    if(name === 'org_name') {
      try {
        let response = await account_api.validate_org_name({organizationName: data});
        if(response && response.message) this.setState({uniqueName: false});
        else this.setState({uniqueName: true});
      } catch (error) {
        console.log(error);
      }
    }
  }

  onFilesAdded(event) {
    const file = event.target.files[0];
    if(file !== null && file !== undefined && file !== '') {
      if (mime_types.VALID_LOGO_MIME_TYPES.includes(file.type)) {
        this.fileInputLabel.current.innerText = "File: " + event.target.value.substr(12, event.target.value.length);
        this.setState({ newLogo: file });
      }
    }
  }

  validate() {
    this.setState({ validations: null });

    let options = {
      org_type: {
        labelName: 'Account Type',
        validations: {
          required: true
        }
      },
      code: {
        labelName: 'Clinician Code',
        validations: {
          required: true
        }
      },
      password_reset_timeframe: {
        labelName: 'Password Reset Time',
        validations: {
          required: true
        }
      },
      auto_logout_time: {
        labelName: 'Auto-Logout Time',
        validations: {
          required: true
        }
      },
      units: {
        labelName: 'Units',
        validations: {
          required: true
        }
      },
      race_ethnicity_display:{
        labelName: 'Race/Ethnicity Screen',
        validations: {
          required: true
        }
      }
    };

    if (types.includes(this.state.org_type)) {
      options = {
        ...options,
        trial_expiration: {
          labelName: 'Trial Expiration',
          validations: {
            required: true
          }
        }
      };
    }

    let validations = helpers.validateInputs(this.state, options);
    this.setState({ validations: validations });
    return validations.isValid;
  }

  render() {
    let { fiveYearChecked, tenYearChecked } = this.state;
    let val = '';
    const super_org_options = this.state.orgs.filter((org) => org.org_type === "super_org" && org.id !== this.state.id).map((org) => {
      if(this.state.parent_org === org.id) val = {value: org.id, label: org.org_name};
      return {value: org.id, label: org.org_name};
    });

    let type_options = (this.state.is_parent) ? [settings.app_constants.superOrg] : [
      settings.app_constants.superOrg,
      settings.app_constants.subOrg,
      settings.app_constants.standardOrg,
      settings.app_constants.trialOrg,
      settings.app_constants.freeOrg,
      settings.app_constants.studentOrg
    ];

    let race_ethnicity_options =  [
      settings.app_constants.ashkanaziAndRace,
      settings.app_constants.ashkanaziOnly,
      settings.app_constants.hideAshkanaziAndRace
    ]

    let race_ethnicity_display_val = null
    let race_ethnicity = this.state.race_ethnicity_display

    switch(race_ethnicity){
      case "show_ashkanazi": { race_ethnicity_display_val = {value: race_ethnicity, label: settings.app_constants.ashkanaziOnly.label}; break; }
      case "show_ashkanazi_and_race": { race_ethnicity_display_val = {value: race_ethnicity, label:settings.app_constants.ashkanaziAndRace.label}; break; }
      case "hide_ashkanazi_and_race": { race_ethnicity_display_val = {value: race_ethnicity, label: settings.app_constants.hideAshkanaziAndRace.label}; break; }
      default: { break; }
    }

    let default_partner_view_options = [
      settings.app_constants.defaultPartnerViewOptions.show_all_partners,
      settings.app_constants.defaultPartnerViewOptions.show_affected_partners,
      settings.app_constants.defaultPartnerViewOptions.hide_partners
    ]

    let default_partner_view = '';
    if (this.state.default_partner_view){
      switch(this.state.default_partner_view){
        case 'show_all_partners': { default_partner_view = settings.app_constants.defaultPartnerViewOptions.show_all_partners; break; }
        case 'show_affected_partners': { default_partner_view = settings.app_constants.defaultPartnerViewOptions.show_affected_partners; break; }
        case 'hide_partners': { default_partner_view = settings.app_constants.defaultPartnerViewOptions.hide_partners; break; }
        default: { break; }
      }
    }

    let auto_logout_time_val = null;
    const auto_logout_time = parseToInt(this.state.auto_logout_time);
    if (auto_logout_time) {
      switch(auto_logout_time) {
        case 15: { auto_logout_time_val = {value: 15, label: '15 Minutes'}; break; }
        case 30: { auto_logout_time_val = {value: 30, label: '30 Minutes'}; break; }
        case 45: { auto_logout_time_val = {value: 45, label: '45 Minutes'}; break; }
        case 60: { auto_logout_time_val = {value: 60, label: '1 Hour'}; break; }
        case 180: { auto_logout_time_val = {value: 180, label: '3 Hours'}; break; }
        case 480: { auto_logout_time_val = {value: 480, label: '8 Hours'}; break; }
      }
    }

    let password_reset_val = '';
    const timeframe = parseToInt(this.state.password_reset_timeframe);
    if(timeframe) {
      switch(timeframe) {
        case 30: { password_reset_val = {value: 30, label: '30 Days'}; break; }
        case 60: { password_reset_val = {value: 60, label: '60 Days'}; break; }
        case 90: { password_reset_val = {value: 90, label: '90 Days'}; break; }
        case 120: { password_reset_val = {value: 120, label: '120 Days'}; break; }
        case 150: { password_reset_val = {value: 150, label: '150 Days'}; break; }
        case 180: { password_reset_val = {value: 180, label: '180 Days'}; break; }
        default: { break; }
      }
    }

    let org_type_val = null;
    let org_type = this.state.org_type;

    switch(org_type) {
      case 'super_org': { org_type_val = settings.app_constants.superOrg; break; }
      case 'sub_org': { org_type_val = settings.app_constants.subOrg; break; }
      case 'standard_org': { org_type_val = settings.app_constants.standardOrg; break; }
      case 'trial_org': { org_type_val = settings.app_constants.trialOrg; break; }
      case 'free': { org_type_val = settings.app_constants.freeOrg; break; }
      case 'student': { org_type_val = settings.app_constants.studentOrg; break; }
      default: { break; }
    }

    const code = (this.state.clinician_code) ? this.state.clinician_code.code: '';

    const password_reset_options = [
      {value: 30, label: '30 Days'},
      {value: 60, label: '60 Days'},
      {value: 90, label: '90 Days'},
      {value: 120, label: '120 Days'},
      {value: 150, label: '150 Days'},
      {value: 180, label: '180 Days'},
    ];

    const auto_logout_time_options = [
      {value: 15, label: '15 Minutes'},
      {value: 30, label: '30 Minutes'},
      {value: 45, label: '45 Minutes'},
      {value: 60, label: '1 Hour'},
      {value: 180, label: '3 Hours'},
      {value: 480, label: '8 Hours'},
    ];

    let boadicea = false;
    let tyrer_cuzick= false;
    let gail = false;
    let claus = false;
    let brcapro = false;
    let mmrpro = false;
    let pancpro = false;
    let melapro = false;
    if(this.state.license) {
      if(this.state.license.boadicea) boadicea = true;
      if(this.state.license.tyrer_cuzick) tyrer_cuzick = true;
      if(this.state.license.gail) gail = true;
      if(this.state.license.claus) claus = true;
      if(this.state.license.brcapro) brcapro = true;
      if(this.state.license.mmrpro) mmrpro = true;
      if(this.state.license.pancpro) pancpro = true;
      if(this.state.license.melapro) melapro = true;
    }

    let save_disabled = (!this.state.validCode && this.state.clinician_code.code !== this.props.org.clinician_code.code) ? " disabled" : "";

    const trial_org = (types.includes(org_type)) ? true : false;

    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block', paddingLeft: '12px' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="edit-super-org"
        id="edit-super-org">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog-wide" role="document">
          <div className="modal-content-edit-account">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Edit Account</h4>
            </div>

            <div className="modal-body">
              <div className="form-horizontal form-label-left form-custom">

                <ValidationErrorSummary
                  validations={this.state.validations}
                />

                <div className="form-group">
                  <label htmlFor="org_name" className="control-label col-md-4 col-sm-3 col-xs-12">Account Name</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <input name="org_name" id="org_name" onChange={this.onChange} value={this.state.org_name} type="text" className="form-control normal-input-text" />
                    {!this.state.uniqueName && this.state.org_name !== this.props.org.org_name && (
                      <div className="validation-inline__error-text validation-warning">
                        Warning: This Company Name is already in use.
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="code" className="control-label col-md-4 col-sm-3 col-xs-12">Clinician Code</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <input name="code" id="code" onChange={this.onChange} value={code} type="text" className="form-control normal-input-text" />
                    {!this.state.validCode && this.state.clinician_code.code !== this.props.org.clinician_code.code && (
                      <div className="validation-inline__error-text validation-warning">
                        <i className='fa fa-times validation-inline__error-text'></i>
                        This Clinician Code is already in use.
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Account Type</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <Select
                      name="org_type"
                      id="org_type"
                      onChange={(item) => {
                        this.setState({ org_type: (item ? item.value : null ) });
                      }}
                      value={org_type_val}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={false}
                      placeholder=""
                      options={type_options}
                    />
                  </div>
                  { this.state.is_parent && (
                    <span className="col-md-8 col-sm-9 col-xs-12 account-warning">*Note: This account must remain a Super Org because it has Sub Orgs attached to it.</span>
                  )}
                </div>

                <div className="form-group">
                  <div className="col-md-4 col-sm-3 col-xs-12">
                    <label htmlFor="surveys" className="control-label">Surveys</label>
                  </div>
                  <div className="col-md-8 col-sm-9 col-xs-12 ">
                    <Select
                      value={this.state.surveys}
                      name="surveys"
                      onChange={(item) => {
                        this.setState({ surveys: (item ? item : null ) });
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable={true}
                      isMulti={true}
                      placeholder=""
                      options={this.state.survey_options}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="code" className="control-label col-md-4 col-sm-3 col-xs-12">Number of Licenses</label>
                  <div className="col-md-3 col-sm-9 col-xs-12">
                    <input name="number_of_licenses" id="number_of_licenses" onChange={this.onChange} value={this.state.number_of_licenses} type="text" className="form-control normal-input-text" />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="code" className="control-label col-md-4 col-sm-3 col-xs-12">Max Number of Pedigrees</label>
                  <div className="col-md-3 col-sm-9 col-xs-12">
                    <input name="max_number_of_pedigrees" id="max_number_of_pedigrees" onChange={this.onChange} value={this.state.max_number_of_pedigrees} type="text" className="form-control normal-input-text" />
                  </div>
                </div>

                {trial_org && (
                  <div className="form-group">
                    <label className="control-label col-md-4 col-sm-3 col-xs-12">Trial Expiration</label>
                    <div className="col-md-3 col-sm-9 col-xs-12">
                      <input name="trial_expiration" id="trial_expiration" onChange={this.onChange} value={this.state.trial_expiration} type="date" className="form-control normal-input-text" />
                    </div>
                  </div>
                )}

                {this.state.org_type && this.state.org_type === 'sub_org' && (
                  <div className="form-group">
                    <label htmlFor="parent_org" className="control-label col-md-4 col-sm-3 col-xs-12">Super Org</label>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <Select
                        name="parent_org"
                        id="parent_org"
                        onChange={(item) => {
                          this.setState({ parent_org: (item ? item.value : null ) });
                        }}
                        value={val}
                        className='react-select-container'
                        classNamePrefix="react-select"
                        isClearable={true}
                        placeholder=""
                        options={super_org_options}
                      />
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">License Details</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="boadicea" id="boadicea" onChange={this.onChange} checked={boadicea} />	BOADICEA
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="tyrer_cuzick" id="tyrer_cuzick" onChange={this.onChange} checked={tyrer_cuzick} /> TYRER-CUZICK
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="gail" id="gail" onChange={this.onChange} checked={gail} /> GAIL
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="claus" id="claus" onChange={this.onChange} checked={claus} /> CLAUS
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="brcapro" id="brcapro" onChange={this.onChange} checked={brcapro} /> BRCAPRO
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="mmrpro" id="mmrpro" onChange={this.onChange} checked={mmrpro} /> MMRPRO
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="pancpro" id="pancpro" onChange={this.onChange} checked={pancpro} /> PANCPRO
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="melapro" id="melapro" onChange={this.onChange} checked={melapro} /> MELAPRO
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Risk Model Years</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                  <CheckboxGroup
                      checkboxWrapClassName="form-check form-check-inline p-0"
                      checkboxWrapTag="div"
                      className="d-flex align-items-center overflow-auto text-nowrap"
                    >

                    <Checkbox
                        checkboxClassName="icheckbox_flat-green check-box-positioning"
                        checked={fiveYearChecked}
                        label="&nbsp;5 year"
                        onChange={(e, value) => {
                          fiveYearChecked = value;
                          this.setState({fiveYearChecked});
                        }}
                      />

                      <Checkbox
                        checkboxClassName="icheckbox_flat-green check-box-positioning"
                        checked={tenYearChecked}
                        label="&nbsp;10 year"
                        onChange={(e, value) => {
                          tenYearChecked = value;
                          this.setState({tenYearChecked});
                        }}
                      />

                    </CheckboxGroup>
                  </div>
                </div>

                <div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Prompt all for gender identity</label>
                      <div className="col-md-8 col-sm-9 col-xs-12">
        								<div className="checkbox">
                        <label>
        										<input type="checkbox" name="show_gender_identity" id="show_gender_identity" onChange={this.onChange} checked={this.state.show_gender_identity} />Show gender identity
                            and pronoun questions on same screen as biological sex
        									</label>
                        </div>
                      </div>
                    </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Race/Ethnicity Screen</label>
                  <div className="col-md-8 col-sm-9 col-xs-12" style={{zIndex: 3}}>
                    <Select
                      name="race_ethnicity_display"
                      id="race_ethnicity_display"
                      onChange={(item) => {
                        this.setState({ race_ethnicity_display: (item ? item.value : null ) });
                      }}
                      value={race_ethnicity_display_val}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder=""
                      options={race_ethnicity_options}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Default partner view</label>
                  <div className="col-md-8 col-sm-9 col-xs-12" style={{zIndex: 2}}>
                    <Select
                      name="default_partner_view"
                      id="default_partner_view"
                      onChange={(item) => {
                        this.setState({ default_partner_view: (item ? item.value : null ) });
                      }}
                      value={default_partner_view}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder=""
                      options={default_partner_view_options}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Units</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <RadioGroup
                      name="units"
                      radioWrapClassName="radio radio-group"
                      radioWrapTag="div"
                      value={this.state.units}
                      onChange={(e, value) => {
                        this.setState({ units: value });
                      }}
                    >

                      <Radio
                        value='metric'
                        radioClass="iradio_flat-green"
                        label="&nbsp;Metric"
                      />

                      <Radio
                        value="us_standard"
                        radioClass="iradio_flat-green"
                        label="&nbsp;US Standard"
                      />

                    </RadioGroup>
                  </div>
                </div>
                <div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Default Dialing Code</label>
        							<div className="col-md-8 col-sm-9 col-xs-12 org_dial" style={{zIndex: 1}}>
                      <IntlTelInput
                      onlyCountries={countryCodes.iso2}
                      name="phone_number"
                      id="phone_number"
                      format
                      autoHideDialCode={false}
                      separateDialCode={true}
                      inputClassName="phone-input"
                      defaultCountry={this.state.dial_code}
                      onSelectFlag={(num, country) => {
                        this.setState({dial_code: country.iso2})
                      }}
                    />
                      </div>
                  </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Customer Logo </label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <div className="row">
                      <div className="col-sm-9">
                        <span className="control-fileupload">
                          <label htmlFor="orgLogo" className="text-left" ref={this.fileInputLabel}>Please choose a file on your computer.</label>
                          <input name="orgLogo" id="orgLogo" type="file" accept="image/png, image/jpeg" onChange={this.onFilesAdded} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Password Reset Time</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <Select
                      name="password_reset_timeframe"
                      id="password_reset_timeframe"
                      onChange={(item) => {
                        this.setState({ password_reset_timeframe: (item ? item.value : null ) });
                      }}
                      value={password_reset_val}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder=""
                      options={password_reset_options}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-4 col-sm-3 col-xs-12">Auto-Logout Time</label>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <Select
                      name="auto_logout_time"
                      id="auto_logout_time"
                      onChange={(item) => {
                        this.setState({ auto_logout_time: (item ? item.value : null ) });
                      }}
                      value={auto_logout_time_val}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder=""
                      options={auto_logout_time_options}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onClickSave}
                type="button" className={"btn btn-dark" + save_disabled}>Save</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalEditSuperAccount;
