import React, { Component } from 'react';
// @ts-ignore
import lodash from 'lodash'

interface Props {
    onOpen: () => void;
    open: boolean;
    setFilterState: (payload: any) => void;
    organizations: any;
}

interface State {
    renewal_date_operator: string;
    renewal_date_from: any;
    renewal_date_to: any;
    trial_expiration_operator: string;
    trial_expiration_from: any;
    trial_expiration_to: any;
    locked_unlocked: string;
    account_type: string;
    client_type: string;
}

let init_state = {
  renewal_date_operator: '=',
  renewal_date_from: null,
  renewal_date_to: null,
  trial_expiration_operator: '=',
  trial_expiration_from: null,
  trial_expiration_to: null,
  locked_unlocked: '',
  account_type: '',
  client_type: ''
};

class AccountFilters extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = init_state;
    this.handleOpenClick = this.handleOpenClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.isActive = this.isActive.bind(this);
    this.loadReportSetFilters = this.loadReportSetFilters.bind(this);
  }

  isActive() {
    let active = lodash.isEqual(this.state, init_state) === false;
    //For regex, regex pattern need to be a string, no '/' on beginning and end, and all '\' should be doubled '\\'
    let dateRegex = '^(((0?[1-9]|1[012])/(0?[1-9]|1\\d|2[0-8])|(0?[13456789]|1[012])/(29|30)|(0?[13578]|1[02])/31)/(19|[2-9]\\d)\\d{2}|0?2/29/((19|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00)))$'
    let dateRegexF = new RegExp(dateRegex)

    let renewal_dates_are_valid = this.state.renewal_date_operator === 'between' ? dateRegexF.test(String(this.state.renewal_date_from)) && dateRegexF.test(String(this.state.renewal_date_to)) : dateRegexF.test(String(this.state.renewal_date_from))
    let trial_dates_are_valid = this.state.trial_expiration_operator === 'between' ? dateRegexF.test(String(this.state.trial_expiration_from)) && dateRegexF.test(String(this.state.trial_expiration_to)) : dateRegexF.test(String(this.state.trial_expiration_from))

    if (active && (renewal_dates_are_valid || trial_dates_are_valid || this.state.locked_unlocked || this.state.account_type || this.state.client_type)) return true;

    return false;
  }

  loadReportSetFilters(filters: any) {
    this.setState({
      renewal_date_operator: filters.renewal_date_operator,
      renewal_date_from: filters.renewal_date_from,
      renewal_date_to: filters.renewal_date_to,
      trial_expiration_operator: filters.trial_expiration_operator,
      trial_expiration_from: filters.trial_expiration_from,
      trial_expiration_to: filters.trial_expiration_to,
      locked_unlocked: filters.locked_unlocked,
      account_type: filters.account_type,
      client_type: filters.client_type
    })
  }

  handleOpenClick() {
    // Toggle
    if(this.props.open) {
      this.props.onOpen();
    //   this.props.handleIsActiveChange('more_filters', this.isActive());
    //   this.props.setFilterState(lodash.cloneDeep(this.state));
    //   this.props.changeMoreFilters(lodash.cloneDeep(this.state), this.isActive())
    } else {
        this.props.onOpen();
    //   this.props.setMoreFilterState(lodash.cloneDeep(this.state));
    }
  }

  handleChange(e: any) {
    //setState callback function
    // @ts-ignore
    this.setState({[e.target.name]: e.target.value}, () => {
      this.props.setFilterState(lodash.cloneDeep(this.state))
    });
  }

  handleClearFilter = () => {
    this.setState({
      renewal_date_operator: '=',
      renewal_date_from: null,
      renewal_date_to: null,
      trial_expiration_operator: '=',
      trial_expiration_from: null,
      trial_expiration_to: null,
      locked_unlocked: '',
      account_type: '',
      client_type: '',
    })
  }

  render() {

    let styleAND = { paddingTop: 28 }
    let btnClass = this.isActive() ? 'btn-purple' : 'btn-light-outline';

    return (
      <React.Fragment>
        <div className="popover-query-wrapper">

          <button
            onClick={this.handleOpenClick}
            className={"btn btn-xs " + btnClass} >Filters</button>

          <div id="morePop"
            style={{ display: (this.props.open ? 'block' : 'none') }}
            className="popover popover-medium popover-x popover-default popover-custom kv-popover-active in bottom popover-query">

            <div className="arrow popover-query__arrow"></div>
            <h5 className="popover-header popover-title">
              <span onClick={this.handleOpenClick}
               className="close pull-right">&times;</span>
              Select
            </h5>

            <div className="popover-body popover-content">

              <div className="row margin-four-bottom">

                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Renewal Date</label>
                  <select
                    name="renewal_date_operator"
                    placeholder="Operator"
                    value={this.state.renewal_date_operator}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    {/* @ts-ignore */}
                    <option default value="=">=</option>
                    <option value="<=">≤</option>
                    <option value="<">&lt;</option>
                    <option value=">">&gt;</option>
                    <option value=">=">≥</option>
                    <option value="between">Between</option>
                  </select>
                </div>
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>&nbsp;</label>
                  <input
                    name="renewal_date_from"
                    value={this.state.renewal_date_from}
                    onChange={this.handleChange}
                    type="text" className="form-control" placeholder="00/00/0000" />
                </div>
                {this.state.renewal_date_operator === 'between' && (
                  <>
                    <div className="col-md-1 col-xs-12 no-padding-right" style={styleAND}>
                      <span>and</span>
                    </div>
                    <div className="col-md-3 col-xs-12 no-padding-right">
                      <label>&nbsp;</label>
                      <input
                        name="renewal_date_to"
                        value={this.state.renewal_date_to}
                        onChange={this.handleChange}
                        type="text" className="form-control" placeholder="00/00/0000" />
                    </div>
                  </>
                )}
              </div>



              <div className="row margin-four-bottom">

                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Trial Expiration</label>
                  <select
                    name="trial_expiration_operator"
                    placeholder="Operator"
                    value={this.state.trial_expiration_operator}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    {/* @ts-ignore */}
                    <option default value="=">=</option>
                    <option value="<=">≤</option>
                    <option value="<">&lt;</option>
                    <option value=">">&gt;</option>
                    <option value=">=">≥</option>
                    <option value="between">Between</option>
                  </select>
                </div>
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>&nbsp;</label>
                  <input
                    name="trial_expiration_from"
                    value={this.state.trial_expiration_from}
                    onChange={this.handleChange}
                    type="text" className="form-control" placeholder="00/00/0000" />
                </div>
                {this.state.trial_expiration_operator === 'between' && (
                  <>
                    <div className="col-md-1 col-xs-12 no-padding-right" style={styleAND}>
                      <span>and</span>
                    </div>
                    <div className="col-md-3 col-xs-12 no-padding-right">
                      <label>&nbsp;</label>
                      <input
                        name="trial_expiration_to"
                        value={this.state.trial_expiration_to}
                        onChange={this.handleChange}
                        type="text" className="form-control" placeholder="00/00/0000" />
                    </div>
                  </>
                )}
              </div>

              <div className="row margin-four-bottom">
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Locked/Unlocked</label>
                  <select
                    name="locked_unlocked"
                    value={this.state.locked_unlocked}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    {/* @ts-ignore */}
                    <option default> </option>
                    <option value="locked">Locked</option>
                    <option value="unlocked">Unlocked</option>
                  </select>
                </div>
              </div>

              <div className="row margin-four-bottom">
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Account Type</label>
                  <select
                    name="account_type"
                    value={this.state.account_type}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    {/* @ts-ignore */}
                    <option default> </option>
                    <option value="super_org">Super Org</option>
                    <option value="sub_org">Sub Org</option>
                    <option value="standard_org">Standard</option>
                    <option value="trial">Trial</option>
                    <option value="free">Free</option>
                    <option value="student">Student</option>
                  </select>
                </div>
              </div>

              <div className="row margin-four-bottom">
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Client Type</label>
                  <select
                    name="client_type"
                    value={this.state.client_type}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    {/* @ts-ignore */}
                    <option default> </option>
                    <option value="standard">Standard</option>
                    <option value="white_label_vpc">White label - VPC</option>
                    <option value="white_label_multi_tenant">White label - Multi-tenant</option>
                  </select>
                </div>
              </div>

            </div>

          </div>
        </div>
      </React.Fragment>



    )
  }
}


export default AccountFilters
