import React, { Component } from "react";
import ReactDOM from "react-dom";

import "custom-event-polyfill";

import ErrorSummary from "./error-summary";
import ModalError from "./modal-error";
import ActivityIndicator from "./activity-indicator";

// @ts-ignore
import { cloneDeep } from "lodash";

// @ts-ignore
import { components } from "react-select";

import family_api from "../api/family-api";

import { PedigreeData } from "../components/react-flow-pedigree/pedigree-data";

// @ts-ignore
import Select from "react-select";

import axios from "axios";
import { clone } from "@babel/types";

interface ModalProps {
  title: string;
  onClose: () => void;
  member: any;
  getPedigreeData: () => PedigreeData;
  navigateToRecord: (member_id: number | string) => void;
  reRenderPedigree: () => void;
  updateSubtextMargin: () => void;
}

interface MenuListProps {
  selectProps: any;
  showMoreOptions: (search_value: string) => Option[];
  clearValue: () => void;
  key: any;
}

interface DelayProps {
  delay: number;
}
interface DelayState {
  visible: boolean;
}

interface LinkingOption {
  label: string;
  value: number | string;
}

interface State {
  loading: boolean;
  show_pedigree_limit_error_modal: boolean;
  patient_id: string;
  first_name: string;
  last_name: string;
  manual_link_input: string;
  errorMessages: string[];
  proband_options: LinkingOption[];
  selected_proband_option: any;
  search_string: string;
  page: number;
  key: number;
}

interface CreatePayload {
  patient_id?: string;
  first_name?: string;
  last_name?: string;
  member_id: number | string;
}

interface LinkPayload {
  member_one_uuid: number | string;
  member_two_uuid: number | string;
}

interface Option {
  label: string;
  value: string;
}

class Expire extends Component<DelayProps, DelayState> {
  timer: any;
  mounted: any;
  constructor(props: DelayProps) {
    super(props);

    this.state = {
      visible: true,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.setTimer();
    this.mounted = true;
  }

  setTimer() {
    // clear any existing timer
    this.timer = this.timer != null ? clearTimeout(this.timer) : null;

    // hide after `delay` milliseconds
    this.timer = setTimeout(() => {
      if (this.mounted) {
        this.setState({ visible: false });
        this.timer = null;
      }
    }, this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.mounted = false;
  }

  render() {
    if (this.state.visible) {
      return <div>{this.props.children}</div>;
    }

    return <span />;

    // return this.state.visible
    //        ? <div>{this.props.children}</div>
    //        : <span />;
  }
}

class MenuList extends Component<MenuListProps> {
  constructor(props: MenuListProps) {
    super(props);
  }

  render() {
    let search_value = this.props.selectProps.inputValue;

    if (Array.isArray(this.props.children)) {
      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            {this.props.children}
            <components.Option {...this.props}>
              {search_value.length >= 3 && (
                <a
                  onClick={(event) => {
                    this.props.selectProps.showMoreOptions(search_value);
                  }}
                >
                  Load More
                </a>
              )}
            </components.Option>
          </components.MenuList>
        </React.Fragment>
      );
    } else {
      // this.props.queryUMLS();
      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            <Expire delay={1000}>
              <ActivityIndicator loading={true} />
            </Expire>
          </components.MenuList>
        </React.Fragment>
      );
    }
  }
}

class ModalCopyLinkPedigree extends Component<ModalProps, State> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      loading: false,
      show_pedigree_limit_error_modal: false,
      patient_id: "",
      first_name: "",
      last_name: "",
      manual_link_input: "",
      errorMessages: [],
      proband_options: [],
      selected_proband_option: "",
      search_string: "",
      page: 1,
      key: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.validatePayloadForCreate = this.validatePayloadForCreate.bind(this);
    this.validatePayloadForLink = this.validatePayloadForLink.bind(this);
    this.handleClickCreate = this.handleClickCreate.bind(this);
    this.handleClickLink = this.handleClickLink.bind(this);
    this.handleManualLinkInputChange =
      this.handleManualLinkInputChange.bind(this);
    this.handleManualLinkInputOptionChange =
      this.handleManualLinkInputOptionChange.bind(this);

    this.loadOptions = this.loadOptions.bind(this);
    this.showMoreOptions = this.showMoreOptions.bind(this);
    this.updateKeyToReRender = this.updateKeyToReRender.bind(this);
  }

  async componentDidMount() {
    let payload = {
      proband_id: this.props.getPedigreeData().getProband().id,
      search_string: this.state.search_string,
      page: this.state.page,
    };
    let proband_options = await family_api.query_list_of_proband_options(
      payload
    );
    this.setState({
      patient_id: this.props.member.patient_id,
      first_name: this.props.member.first_name,
      last_name: this.props.member.last_name,
      proband_options,
    });
  }

  updateKeyToReRender() {
    this.setState({ key: this.state.key + 1 });
  }

  async loadOptions(inputValue: string) {
    let payload = {
      proband_id: this.props.getPedigreeData().getProband().id,
      search_string: this.state.search_string,
      page: this.state.page,
    };

    let proband_options = await family_api.query_list_of_proband_options(
      payload
    );
    this.setState({ proband_options });
    return proband_options;
  }

  async showMoreOptions(inputValue: string) {
    let payload = {
      proband_id: this.props.getPedigreeData().getProband().id,
      search_string: inputValue,
      page: this.state.page + 1,
    };
    let more_proband_options = await family_api.query_list_of_proband_options(
      payload
    );

    let proband_options = cloneDeep(this.state.proband_options);
    proband_options = proband_options.concat(more_proband_options);

    this.setState({ proband_options, page: payload.page });
    return proband_options;
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    // @ts-ignore
    this.setState({ [event.target.name]: event.target.value });
  }

  handleManualLinkInputOptionChange(option: any) {
    this.setState({ selected_proband_option: option });
  }

  handleManualLinkInputChange(search_string: string) {
    this.setState({ search_string, page: 1 }, () =>
      this.loadOptions(this.state.search_string)
    );
  }

  async validatePayloadForCreate(payload: CreatePayload) {
    if (
      (payload.patient_id === undefined ||
        payload.patient_id === null ||
        !payload.patient_id) &&
      (payload.first_name === undefined ||
        payload.first_name === null ||
        !payload.first_name)
    ) {
      throw new Error("Either First Name or Patient ID is required.");
    } else {
      this.setState({ errorMessages: [] });
    }
  }

  async handleClickCreate(event: React.MouseEvent<HTMLElement>) {
    try {
      this.setState({ errorMessages: [], loading: true });
      event.preventDefault();
      let payload: CreatePayload = {
        patient_id: this.state.patient_id,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        member_id: this.props.member.id,
      };

      await this.validatePayloadForCreate(payload);

      let data = await family_api.clone_with_new_proband(payload);

      let patch_payload = cloneDeep(payload);
      delete patch_payload.patient_id;
      // @ts-ignore
      delete patch_payload.member_id;

      await family_api.patch_member_memberid(
        this.props.member.id,
        patch_payload
      );

      let profile = this.props
        .getPedigreeData()
        .getProfile(this.props.member.rkey);
      profile.first_name = payload.first_name;
      profile.last_name = payload.last_name;
      this.props.getPedigreeData().setProfile(this.props.member.rkey, profile);

      let probands = cloneDeep(this.props.getPedigreeData().getClonedMembers());
      probands.push(data.new_proband);
      this.props.getPedigreeData().setClonedMembers(probands);

      this.setState({ loading: false });

      this.props.onClose();

      this.props.navigateToRecord(data.new_proband.id);
    } catch (error: any) {
      console.log(error);
      if(error.message == 'Maximum pedigrees for this organization has been reached.'){
        this.setState({ show_pedigree_limit_error_modal: true });
      }
      else{
        this.setState({ errorMessages: [error.message] });
      }
      this.setState({ errorMessages: [error.message], loading: false });
    }
  }

  async validatePayloadForLink(payload: LinkPayload) {
    if (
      (payload.member_one_uuid === undefined ||
        payload.member_one_uuid === null ||
        !payload.member_one_uuid) &&
      (payload.member_two_uuid === undefined ||
        payload.member_two_uuid === null ||
        !payload.member_two_uuid)
    ) {
      throw new Error("Something went wrong.");
    } else {
      this.setState({ errorMessages: [] });
    }
  }

  async handleClickLink(event: React.MouseEvent<HTMLElement>) {
    try {
      this.setState({ errorMessages: [], loading: true });
      event.preventDefault();
      let payload: LinkPayload = {
        member_one_uuid: this.props.member.uuid_code,
        member_two_uuid: this.state.selected_proband_option.value,
      };

      await this.validatePayloadForLink(payload);

      let data = await family_api.link_with_existing_pedigree(payload);

      // push to member links on pedigree data store
      let member_links = cloneDeep(
        this.props.getPedigreeData().getMemberLinks()
      );
      member_links.push(data.member_link);
      this.props.getPedigreeData().setMemberLinks(member_links);

      // push to linked members on pedigree data store
      let linked_members = cloneDeep(
        this.props.getPedigreeData().getLinkedMembers()
      );
      linked_members.push(data.proband_record);
      this.props.getPedigreeData().setLinkedMembers(linked_members);

      this.setState({ loading: false });

      this.props.onClose();

      await this.props.reRenderPedigree();
      this.props.updateSubtextMargin();
    } catch (error: any) {
      console.log(error);
      this.setState({ errorMessages: [error.message], loading: false });
    }
  }

  render() {
    return ReactDOM.createPortal(
      <React.Fragment>
        <div
          style={{ display: "block", zIndex: 9999 }}
          className="modal fade in"
          role="dialog"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="modal-dialog modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  onClick={() => this.props.onClose()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close" />
                </button>
                <h4 className="modal-title text-white text-capitalize">
                  {this.props.title}
                </h4>
              </div>

              <div className="modal-body">
                <ErrorSummary
                  transparent={true}
                  errorMessages={this.state.errorMessages}
                />
                <div className="row" style={{ display: "flex" }}>
                  <div
                    className="col-md-6 col-sm-12"
                    style={{ marginRight: 100 }}
                  >
                    <div style={{ marginBottom: 15 }}>
                      Copy pedigree and make this person a proband:
                      <span className="i-tooltip-right">
                        <i className="fa fa-info-circle"></i>
                        <span
                          className="i-tooltiptext-right"
                          style={{ textAlign: "left" }}
                        >
                          Use “Create” when you want to copy an existing
                          pedigree and make someone else the proband in the new
                          pedigree. This will maintain a link between the two
                          patients, allowing them to share with each other
                          through the patient app if they both are invited and
                          have accounts created. Data changes will not be shared
                          between the two provider pedigrees to maintain patient
                          privacy.
                        </span>
                      </span>
                    </div>

                    <div className="row" style={{ marginBottom: -5 }}>
                      <div className="form-group">
                        <div className="col-md-12 form-group">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              className="col-md-5 control-label"
                              htmlFor="copy_link_patient_id"
                            >
                              Patient ID
                            </label>
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control"
                              name="patient_id"
                              value={this.state.patient_id}
                              id="copy_link_patient_id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginBottom: -5 }}>
                      <div className="form-group">
                        <div className="col-md-12 form-group">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              className="col-md-5 control-label"
                              htmlFor="copy_link_first_name"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control"
                              name="first_name"
                              value={this.state.first_name}
                              id="copy_link_first_name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginBottom: 10 }}>
                      <div className="form-group">
                        <div className="col-md-12 form-group">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              className="col-md-5 control-label"
                              htmlFor="copy_link_last_name"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control"
                              name="last_name"
                              value={this.state.last_name}
                              id="copy_link_last_name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        type="button"
                        onClick={(event) => this.handleClickCreate(event)}
                        className="btn btn-dark "
                        data-toggle="modal"
                        data-dismiss="modal"
                      >
                        Create
                      </button>
                    </div>
                  </div>

                  <div className="vertical-line"></div>

                  <div className="col-md-6 col-sm-11">
                    <div style={{ marginBottom: 15 }}>
                      <span style={{ marginLeft: -10 }}>
                        or, link to an existing record:
                      </span>
                      <span className="i-tooltip-right">
                        <i className="fa fa-info-circle"></i>
                        <span
                          className="i-tooltiptext-right"
                          style={{ textAlign: "left" }}
                        >
                          Use “Link” when there are already two pedigrees
                          created and you want to see that a family member in
                          one pedigree has their own record. If the patients
                          created accounts separately without being invited by
                          their family member, and are therefore not sharing
                          with each other, linking does not allow them to begin
                          sharing. It only allows you to see that there is
                          another record and to open it easily. Data changes
                          will not be shared between the two provider pedigrees
                          to maintain patient privacy.
                        </span>
                      </span>
                    </div>

                    <div className="row" style={{ marginBottom: 15 }}>
                      <div className="form-group">
                        <div className="col-12 form-group">
                          <div style={{ display: "flex" }}>
                            <div className="row">
                              <div style={{ width: "250px" }}>
                                <Select
                                  key={JSON.stringify(this.state.key)}
                                  value={this.state.selected_proband_option}
                                  id="manual_link_input"
                                  name="manual_link_input"
                                  onChange={(option: any) =>
                                    this.handleManualLinkInputOptionChange(
                                      option
                                    )
                                  }
                                  onInputChange={(search_string: string) =>
                                    this.handleManualLinkInputChange(
                                      search_string
                                    )
                                  }
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  isClearable={true}
                                  placeholder=""
                                  options={this.state.proband_options}
                                  // loadOptionsFunc={(inputValue: string) => this.loadOptions(inputValue)}
                                  // addToPage={this.addToPage}
                                  showMoreOptions={this.showMoreOptions}
                                  // updateKeyToReRender={this.updateKeyToReRender}
                                  components={{ MenuList }}
                                  // defaultOptions
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={(event) => this.handleClickLink(event)}
                              className="btn btn-dark "
                              style={{
                                marginLeft: 40,
                                width: "60px",
                                textAlign: "center",
                              }}
                              data-toggle="modal"
                              data-dismiss="modal"
                            >
                              Link
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => this.props.onClose()}
                  className="btn btn-light-outline no-margin-right "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>

              {this.state.show_pedigree_limit_error_modal && (
                <ModalError
                  pedigree_limit_error={true}
                  message="You have reached the limit of pedigrees for a free account. Please contact us to upgrade."
                  onClose={() => this.setState({ show_pedigree_limit_error_modal: false })}
                />
              )}

              <ActivityIndicator loading={this.state.loading} modal={true} />
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    );
  }
}

export default ModalCopyLinkPedigree;
