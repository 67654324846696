import React, {Component} from 'react';
import {
  PedigreeStylesheet,
  oneClickAddDropdowns as dropdowns, oneClickAddActionTypes as actions
} from './pedigree-constants';

class AddChildrenDOM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_pregnancy_dropdown: false,
      show_son_dropdown: false,
      show_daughter_dropdown: false,
      show_unknown_dropdown: false
    };
  }

  render() {
    const show_dropdown = this.props.showDropdown ? 'open' : '';

    const container_styles = Object.assign({}, PedigreeStylesheet.oneClickDOMContainer, {top: this.props.top, left: this.props.left});
    const male_btn_style = Object.assign({}, PedigreeStylesheet.oneClickAddBtn, PedigreeStylesheet.oneClickAddBtnLeft);
    const female_btn_style = Object.assign({}, PedigreeStylesheet.oneClickAddBtn, PedigreeStylesheet.oneClickAddBtnMiddle);
    const unknown_btn_style = Object.assign({}, PedigreeStylesheet.oneClickAddBtn, PedigreeStylesheet.oneClickAddBtnMiddle);
    const dropdown_btn_style = Object.assign({}, PedigreeStylesheet.oneClickAddBtn, PedigreeStylesheet.oneClickAddBtnRight);
    const svg_symbol_style = PedigreeStylesheet.oneClickAddSvgSymbol;
    const svg_style = PedigreeStylesheet.oneClickAddSvg;

    const dropdown_menu_styles = {
      fontFamily: 'sans-serif',
      fontSize: 12,
      backgroundColor: '#ffffff'
    };

    const children_dropdown_label = Object.assign({}, dropdown_menu_styles, {fontWeight: 'bold', textAlign: 'justify', padding: '15px 20px', whiteSpace: 'nowrap'});
    const label_chevron = {float: 'right', paddingTop: '3px'};

    const default_num_to_add = 1;
    return (
      <div style={container_styles}>
        <button
          onClick={() => this.props.onClickAdd(actions.SON, default_num_to_add)}
          className='btn-default'
          style={male_btn_style}>

          <svg style={svg_style}>
            <rect
              x={PedigreeStylesheet.oneClickAddSvgSymbolMale.x}
              y={PedigreeStylesheet.oneClickAddSvgSymbolMale.y}
              width={PedigreeStylesheet.oneClickAddSvgSymbolMale.width}
              height={PedigreeStylesheet.oneClickAddSvgSymbolMale.height}
              style={svg_symbol_style}
            />
          </svg>
        </button>

        <button
          onClick={() => this.props.onClickAdd(actions.DAUGHTER, default_num_to_add)}
          className='btn-default'
          style={female_btn_style}>

          <svg style={svg_style}>
            <circle
              cx={PedigreeStylesheet.oneClickAddSvgSymbolFemale.cx}
              cy={PedigreeStylesheet.oneClickAddSvgSymbolFemale.cy}
              r={PedigreeStylesheet.oneClickAddSvgSymbolFemale.r}
              style={svg_symbol_style}
            />
          </svg>
        </button>

        <button
          onClick={() => this.props.onClickAdd(actions.UNKNOWN, default_num_to_add)}
          className='btn-default'
          style={unknown_btn_style}>

          <svg style={svg_style}>
            <path
              d={PedigreeStylesheet.oneClickAddSvgSymbolUnknown.d}
              style={svg_symbol_style}
            />
          </svg>
        </button>

        <div className={"dropdown " + show_dropdown}>
          <button
            className='btn-default'
            data-toggle="dropdown_children_dom"
            aria-haspopup="true"
            aria-expanded="false"
            style={dropdown_btn_style}
            onClick={() => this.props.onDropdownClicked(dropdowns.ADD_CHILDREN)}>

            <i className="fa fa-angle-down"></i>
          </button>

          <ul className="dropdown-menu" aria-labelledby="dropdown_children_dom">
            <li style={children_dropdown_label}>Children</li>

            <li onMouseOver={() => this.setState({show_son_dropdown: true})}
                onMouseLeave={() => this.setState({show_son_dropdown: false})}
                style={dropdown_menu_styles}>

              <a>Son <span className="fa fa-plus" style={label_chevron}></span></a>
              {this.state.show_son_dropdown && (
                <ul className="dropdown-menu"
                    style={Object.assign({}, PedigreeStylesheet.multiAddMenu, {top: '30px'})}
                    onMouseOver={() => this.setState({show_son_dropdown: true})}>
                  <li onClick={() => this.props.onClickAdd(actions.SON, 2)} style={dropdown_menu_styles}><a>+2</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.SON, 3)} style={dropdown_menu_styles}><a>+3</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.SON, 4)} style={dropdown_menu_styles}><a>+4</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.SON, 5)} style={dropdown_menu_styles}><a>+5</a></li>
                </ul>
              )}
            </li>

            <li onMouseOver={() => this.setState({show_daughter_dropdown: true})}
                onMouseLeave={() => this.setState({show_daughter_dropdown: false})}
                style={dropdown_menu_styles}>

              <a>Daughter <span className="fa fa-plus" style={label_chevron}></span></a>
              {this.state.show_daughter_dropdown && (
                <ul className="dropdown-menu"
                    style={Object.assign({}, PedigreeStylesheet.multiAddMenu, {top: '55px'})}
                    onMouseOver={() => this.setState({show_daughter_dropdown: true})}>
                  <li onClick={() => this.props.onClickAdd(actions.DAUGHTER, 2)} style={dropdown_menu_styles}><a>+2</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.DAUGHTER, 3)} style={dropdown_menu_styles}><a>+3</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.DAUGHTER, 4)} style={dropdown_menu_styles}><a>+4</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.DAUGHTER, 5)} style={dropdown_menu_styles}><a>+5</a></li>
                </ul>
              )}
            </li>

            <li onMouseOver={() => this.setState({show_unknown_dropdown: true})}
                onMouseLeave={() => this.setState({show_unknown_dropdown: false})}
                style={dropdown_menu_styles}>

              <a>Unknown <span className="fa fa-plus" style={label_chevron}></span></a>
              {this.state.show_unknown_dropdown && (
                <ul className="dropdown-menu"
                    style={Object.assign({}, PedigreeStylesheet.multiAddMenu, {top: '80px'})}
                    onMouseOver={() => this.setState({show_unknown_dropdown: true})}>
                  <li onClick={() => this.props.onClickAdd(actions.UNKNOWN, 2)} style={dropdown_menu_styles}><a>+2</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.UNKNOWN, 3)} style={dropdown_menu_styles}><a>+3</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.UNKNOWN, 4)} style={dropdown_menu_styles}><a>+4</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.UNKNOWN, 5)} style={dropdown_menu_styles}><a>+5</a></li>
                </ul>
              )}
            </li>

            {this.props.canAddNoChildrenOrInfertility() &&
              <React.Fragment>
                <li onClick={() => this.props.onClickAdd(actions.NO_CHILDREN, default_num_to_add)} style={dropdown_menu_styles}><a>No Children</a></li>
                <li onClick={() => this.props.onClickAdd(actions.INFERTILITY, default_num_to_add)} style={dropdown_menu_styles}><a>Infertility</a></li>
              </React.Fragment>
            }

            <li onClick={() => this.props.onClickAdd(actions.SAB, default_num_to_add)} style={dropdown_menu_styles}><a>SAB</a></li>

            <li onMouseOver={() => this.setState({show_pregnancy_dropdown: true})}
                onMouseLeave={() => this.setState({show_pregnancy_dropdown: false})}
                style={dropdown_menu_styles}>

              <a>Pregnancy <span className="fa fa-angle-right" style={label_chevron}></span></a>
              {this.state.show_pregnancy_dropdown && (
                <ul className="dropdown-menu"
                    style={PedigreeStylesheet.dropright}
                    onMouseOver={() => this.setState({show_pregnancy_dropdown: true})}>
                  <li onClick={() => this.props.onClickAdd(actions.MALE_FETAS, default_num_to_add)} style={dropdown_menu_styles}><a>Male Fetus </a></li>
                  <li onClick={() => this.props.onClickAdd(actions.FEMALE_FETAS, default_num_to_add)} style={dropdown_menu_styles}><a>Female Fetus</a></li>
                  <li onClick={() => this.props.onClickAdd(actions.PREGNANCY_UNKNOWN, default_num_to_add)} style={dropdown_menu_styles}><a>Pregnancy Unknown</a></li>
                </ul>
              )}
            </li>
          </ul>

        </div>
      </div>
    );
  }
}

export default AddChildrenDOM;
