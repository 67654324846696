import React from 'react';
import Toolbar from './toolbar'
import Settings from './settings'
import NotificationTabs from './notification-tabs'
export default props => {
  return (
    <div className="flex-grid-sidebar">
      <div className="col col-left">

        <Toolbar />
        <NotificationTabs onNotificationAction={props.NotificationAction} notificationUpdates={props.notificationUpdates} />

      </div>

      <Settings getPedigreeData={props.getPedigreeData} />
    </div>
  )
}