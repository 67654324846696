import React from "react";
import images from '../../../configs/images';
import moment from 'moment';
import ActivityIndicator from "../../activity-indicator";

export const PendingNotificationItem = props => {
  let { notification, onAccept, onDecline, activeNotificationId } = props;
  let options = {year: 'numeric', month: 'long', day: 'numeric' };
  if(notification === undefined) return null;
  let message = notification.message_body.split('</br>').join('<br />');
  message = message.split("\n").join('<br />')
  message = message.split("<br /><br />").join('<br />')
  let created_date = new Date(notification.created_at).toLocaleDateString(undefined, options)
  let created_time = moment(notification.created_at).format("H:m A");
  return (
    <div className="panel panel-default patient-notif">
    <div className="panel-body--no-updates">
      <div className="row">
        <div className="col-md-1">
          <img
            src={images.iconNotification}
            alt="notification-icon"
            className="margin-four-bottom"
          />
        </div>
        <div className="col-md-8">
          <p className="">
            <span dangerouslySetInnerHTML={{__html: message}} />
            <br />
            <small>{created_date} | {created_time} </small>
          </p>
        </div>
        <div className="col-md-3 text-right btn--updates">
          {activeNotificationId === notification.id &&
            <ActivityIndicator loading={true} />
          }
          {activeNotificationId !== notification.id &&
            <React.Fragment>
              <a onClick={() => onAccept(notification) } className="btn btn-teal--outline no-margin">
                Accept
              </a>&nbsp;
              <a onClick={() => onDecline(notification) }  className="btn btn-light--outline no-margin">
                Reject
              </a>
            </React.Fragment>
          }
        </div>
      </div>
    </div>
  </div>
  );
}

export const CompletedNotificationItem = props => {
  let { notification } = props;
  if(notification === undefined) return null;
  let options = {year: 'numeric', month: 'long', day: 'numeric' };
  let message = notification.message_body.split('</br>').join('<br />');
  message = message.split("\n").join('<br />')
  message = message.split("<br /><br />").join('<br />')

  let completed_text = notification.response == 'deny' ? 'Rejected' : 'Accepted';

  let created_date = new Date(notification.created_at).toLocaleDateString(undefined, options)
  let created_time = moment(notification.created_at).format("H:m A");
  let completed_date = new Date(notification.modified_at).toLocaleDateString(undefined, options)
  let completed_time = moment(notification.modified_at).format("H:m A");

  let { user } = notification;
  return (
    <div className="panel panel-default patient-notif">
    <div className="panel-body--no-updates">
      <div className="row">
        <div className="col-md-1">
          <img
            src={images.iconNotification}
            alt="notification-icon"
            className="margin-four-bottom"
          />
        </div>
        <div className="col-md-6">
          <p className="">

            <span dangerouslySetInnerHTML={{__html: message}} />
            <br />
            <small>{created_date} | {created_time} </small>
            <br />
          </p>

          { user &&
            <p style={{ color: "#414661" }}>
              <small>
                <strong>{user.first_name} {user.last_name} </strong> &nbsp;
                {completed_text} &nbsp;
                {completed_date} | {completed_time}
                <br />
              </small>
            </p>
          }

        </div>
      </div>
    </div>
  </div>
  );
}